/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#nav-trigger').click(function(){
          $('.nav-mobile select').toggleClass('js-show');
          $(this).toggleClass('js-closed');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('input[type=text]').focus(function() {
          $('label[for=' + this.id + ']').addClass('js-active');
        }).blur(function() {
          // $("label").removeClass("js-show");
        });
      }
    },
    // Home page
    'page_template_page_home': {
      init: function() {
        // JavaScript to be fired on the home page template
        $('#slider').slick({
          dots: true,
          speed: 500,
          adaptiveHeight: true,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: true,
          prevArrow: '<button type="button" class="slick-prev"><svg class="svg--arrow" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"viewBox="0 0 161.495 444.5" xml:space="preserve"><path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M161.495,444.5L45.612,222.258L161.495,0h-45.604 L0,222.258L115.892,444.5H161.495z"/></svg></button>',
          nextArrow: '<button type="button" class="slick-next"><svg class="svg--arrow svg--arrow--right" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"viewBox="0 0 161.495 444.5" xml:space="preserve"><path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M161.495,444.5L45.612,222.258L161.495,0h-45.604 L0,222.258L115.892,444.5H161.495z"/></svg></button>'
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page template, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

   $('#mobile-nav').on('change', function(){
      var url = $(this).val();
      console.log(url);
      window.location = url;
   });

   //Tooltips for catalog pages
   $('.tooltip').tooltipster({
    trigger: 'hover'
   });

   $('.search-field').on('click', function(){
      $(this).text(' ');
   });

   $('#input_1_11').click(function(){
      $('.gfield_label[for="input_1_11"]').fadeOut(200);
   });
   // Toggle login and registration forms
   $('.login_form_toggle').hide();
   $('#registration_form').hide();
   $('.login_text').text('Please login to create your product list. If you don\'t have and account please click the "Register" button to register for an account.');

   $('.register_form_toggle').on('click', function(){
    $('#registration_form').show();
    $(this).hide();
    $('.login_form_toggle').show();
    $('#login_form').hide();
    $('.login_text').text('Please register for an account to create your product list. If you already have an account please click the "Login" button to log in to your account.');
   });

   $('.login_form_toggle').on('click', function(){
    $('#registration_form').hide();
    $(this).hide();
    $('.register_form_toggle').show();
    $('#login_form').show();
    $('.login_text').text('Please login to create your product list. If you don\'t have an account please click the "Register" button to register for an account.');
   });

   var parent = $('.catalog-link');
   var child = $('.sub_menu_wrap');

   child.mouseover(function() {
    parent.addClass('hover');
   });

   child.mouseout(function() {
    parent.removeClass('hover');
   });

   var name = $('.doc-name');
   var icon = $('.doc-icon');
   var windowWidth = $(window).width();
   var userAgent = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
   
   console.log(userAgent);

  if(windowWidth > 900 || userAgent == true ) {
   $('.doc').mouseover(function(){
      $(this).find(icon).attr('style', 'width:14%;');
      $(this).find(name).attr('style', 'width:84%');
   });

   $('.doc').mouseout(function(){
      $(this).find(icon).attr('style', 'width:8%;');
      $(this).find(name).attr('style', 'width:90%');
   });
 } 
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
